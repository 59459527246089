<template>
    <div class="faq-content">
       <div class="faq-list">
           <div class="faq-item"
                v-for="(item, index) in list"
                :key="index"
                :class="{'isActive': item.isActive}"
           >
               <div class="faq-title" @click="toggleFAQ(index)">
                   {{item.title}}
                   <div class="faq-arrow">
                       <svg width="10" height="7"
                            viewBox="0 0 10 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                       >
                           <path
                                   d="M5.59996 6.14997C5.48286 6.26722 5.324 6.33316
5.15829 6.33331H4.84163C4.67628 6.33139 4.51806 6.26571 4.39996 6.14997L0.124958
1.86664C0.046078 1.7884 0.00170898 1.68191 0.00170898 1.57081C0.00170898 1.45971
0.046078 1.35321 0.124958 1.27497L0.716625 0.683308C0.793428 0.604921 0.89855
0.560748 1.00829 0.560748C1.11803 0.560748 1.22316 0.604921 1.29996
0.683308L4.99996 4.39164L8.69996 0.683308C8.7782 0.604428 8.88469 0.560059
8.99579 0.560059C9.10689 0.560059 9.21339 0.604428 9.29163 0.683308L9.87496
1.27497C9.95384 1.35321 9.99821 1.45971 9.99821 1.57081C9.99821 1.68191
9.95384 1.7884 9.87496 1.86664L5.59996 6.14997Z"
                                   fill="black"
                           />
                       </svg>
                   </div>
               </div>
               <div class="faq-text" v-html="item.text"></div>
           </div>
       </div>
    </div>
</template>

<script>

export default {
  name: 'FAQPage',
  components: {},
  data() {
    return {
      list: this.$store.state.general.faqList,
    };
  },
  computed: {
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
  },
  methods: {
    toggleFAQ(index) {
      this.list.forEach((listItem, listIndex) => {
        if (listIndex === index && !listItem.isActive) {
          this.list[listIndex].isActive = true;
        } else {
          this.list[listIndex].isActive = false;
        }
      });
    },
  },
  mounted() {
    const cabinetLink = document.querySelector('.js-cabinetLink');
    cabinetLink.addEventListener('click', () => {
      if (this.getUserMail !== '') {
        this.$router.push({ name: 'My' });
      } else {
        this.$router.push({ name: 'Auth' });
      }
    }, true);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
@import "../../assets/scss/common/variables";
@import "../../assets/scss/common/faq";

.faq-content {
    margin: 30px 0;
    font-size: 14px;
    line-height: 20px;
    padding: 0 24px;
    background-size: cover;
    min-height: calc(100vh - 240px);
}

</style>
