<template>
  <div class="faq-page">
    <BaseSecondaryPagesHeader title="Вопрос-ответ"></BaseSecondaryPagesHeader>
    <FAQ></FAQ>
  </div>
</template>

<script>
import BaseSecondaryPagesHeader from '@/components/header/BaseSecondaryPagesHeader.vue';
import FAQ from '@/components/secondary-pages/FAQ.vue';

export default {
  name: 'Home',
  components: {
    BaseSecondaryPagesHeader, FAQ,
  },
  mounted() {
    document.documentElement.style.setProperty('--main-bg-color', '#f6f7fb');
  },
  beforeDestroy() {
    document.documentElement.style.setProperty('--main-bg-color', '#ffffff');
    this.$store.commit('showBurger');
  },
};
</script>
