<template>
    <header class="secondary-header">
        <a @click="$router.go(-1)" class="secondary-header__action-button"></a>
        <div class="secondary-header__title">{{title}}</div>
    </header>
</template>

<script>

export default {
  name: 'HelloWorld',
  props: {
    title: String,
    backUrl: String,
  },
  components: {},
  data() {
    return {
      complete: false,
    };
  },
  computed: {
    getStripeKey() {
      return 'pk_test_EbFCyRpagCLakgHBurKM1Hkk';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";

.secondary-header {
    height: 60px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &__action-button {
        position: absolute;
        left: 16px;
        top: 16px;
        width: 24px;
        height: 24px;
        background: $main-blue;
        border-radius: 50%;
        border: solid 10px var(--main-bg-color);
        cursor: pointer;

        &:after {
            background: url('/images/right-arrow.svg') 50% 50% no-repeat;
            content: '';
            width: 24px;
            height: 24px;
            display: block;
            position: relative;
            left: -1px;
        }
    }

    &__title {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        text-align: center;
        width: 100%;
        padding: 0 40px 0 40px;
    }
}
</style>
